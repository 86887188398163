<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main *ngIf="account">
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-2">
                <h1 class="page-header-title">
                  Customer Portal
                </h1>
                <div class="page-header-subtitle">Everything you need to get started with the STRICH SDK.</div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container-xl px-4 mt-n10">
        <div class="row" *ngIf="showExpiringLicenseKeysWarning">
          <div class="col">
            <div class="alert alert-warning d-flex align-items-center" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div>
                You have offline license keys that will expire in less than 60 days. Make sure to replace
                them with up-to-date ones to avoid interruptions in your scanning apps.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mt-4">

            <a class="card lift h-100" href="https://docs.strich.io/reference/index.html?ref=portal" target="_blank">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-3">
                    <i-feather class="feather-xl text-primary mb-3" name="book-open"></i-feather>
                    <h5>API Reference</h5>
                    <div class="text-muted small">
                      The reference API documentation for the latest SDK.
                    </div>
                  </div>
                </div>
              </div>
            </a>

          </div>
          <div class="col-md-4 mt-4">

            <a class="card lift h-100" href="https://docs.strich.io/getting-started.html?ref=portal" target="_blank">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-3">
                    <i-feather class="feather-xl text-secondary mb-3" name="play"></i-feather>
                    <h5>Getting Started</h5>
                    <div class="text-muted small">
                      Step-by-step instructions for getting your scanning app up and running.
                    </div>
                  </div>
                </div>
              </div>
            </a>

          </div>
          <div class="col-md-4 mt-4">
            <a class="card lift h-100" href="https://strich.io/faq.html" target="_blank">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-3">
                    <i-feather class="feather-xl text-green mb-3" name="archive"></i-feather>
                    <h5>Knowledge Base / FAQ</h5>
                    <div class="text-muted small">
                      Best practices, frequently asked questions and common pitfalls.
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div> <!-- row -->
        <div class="row">

          <div class="col-md-6 mt-4">
            <div *ngIf="!account.hasSubscription" class="card lift">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-3">
                    <i-feather class="feather-xl text-green mb-3" name="meh"></i-feather>
                    <h5 class="card-title">No subscription yet</h5>
                    <p class="card-text">
                      To get started using STRICH, you need to create a subscription first.
                    </p>
                    <button (click)="showStartTrialModal()" class="btn btn-primary">Start Free 30-day Trial</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- custom subscription check first: might have been upgraded from a Paddle subscription/trial -->

            <div *ngIf="account.hasCustomSubscription else noCustomSubscription" class="card lift">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-3">
                    <i-feather class="feather-xl text-green mb-3" name="check-circle"></i-feather>
                    <h5 class="card-title">Subscription</h5>
                    <p class="card-text">
                      You have a custom subscription of type <strong>{{ account.customSubscriptionType }}</strong>.
                    </p>
                    <button class="btn btn-secondary" routerLink="/subscription">Show subscription</button>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #noCustomSubscription>
              <div *ngIf="account.isPaddle" class="card lift">
                <div class="card-body d-flex justify-content-center flex-column">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="me-3">
                      <i-feather class="feather-xl text-green mb-3" [name]="subscriptionIconName"></i-feather>
                      <h5 class="card-title">Subscription</h5>
                      <p class="card-text">
                        You have a subscription of
                        <strong>{{ account.subscriptionName }}</strong> in <strong>{{ account.subscriptionState }}</strong> state.
                      </p>
                      <button class="btn btn-secondary" routerLink="/subscription">Show subscription</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

          </div> <!-- col -->

          <div class="col-md-6 mt-4">
            <div class="card lift">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-3">
                    <i-feather class="feather-xl text-primary mb-3" name="help-circle"></i-feather>
                    <h5 class="card-title">Need help?</h5>
                    <p class="card-text">
                      Are you facing issues with your subscription or having trouble integrating the SDK?
                    </p>
                    <a routerLink="/support" class="btn btn-outline-primary">Contact Support</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- welcome dialog, displayed after signup -->
      <div id="startTrialModal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">🎉 Welcome to STRICH!</h5>
            </div>
            <div class="modal-body">
              <p>
                To get started with the SDK right away, you need to start a free trial.
                We recommend starting out with STRICH Basic.
              </p>

              <div class="container-fluid mb-4 mt-4">
                <div class="row">
                  <div class="col-sm-8">
                    <b>STRICH Basic</b><br>
                    1D/2D barcode scanning<br>
                    Up to 10k scans per month<br>
                    Unlimited devices<br>
                    Unlimited applications<br>
                  </div>
                  <div class="col-sm-4">
                    <img src="assets/img/strich_200x200.svg" width="120" height="120" alt="STRICH Logo">
                  </div>
                </div>
              </div>

              <div class="form-check mb-2">
                <input class="form-check-input" id="licenseAgreementAcceptedCheckbox" type="checkbox"
                       [disabled]="hasAcceptedLicenseAgreement" [(ngModel)]="hasAcceptedLicenseAgreement">
                <label class="form-check-label text-sm" for="licenseAgreementAcceptedCheckbox">
                  I accept the terms of the
                  <a href="https://strich.io/assets/legal/STRICH_Subscription_License_Agreement.pdf" target="_blank">License Agreement</a>.
                </label>
              </div>

              <p class="mb-0 text-muted text-sm">
                During the 30-day trial period, your credit card will not be charged. You can always cancel if the
                product does not meet your expectations.
              </p>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" [disabled]="!hasAcceptedLicenseAgreement" (click)="startTrialFromWelcomeModal()">Start Free 30-Day Trial</button>
              <button type="button" class="btn btn-link" data-bs-dismiss="modal">Maybe later</button>
            </div>
          </div>
        </div>
      </div>

    </main>
    <app-footer></app-footer>
  </div>
</div>

