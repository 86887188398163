<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <div *ngIf="account" class="container-xl px-4">
        <div class="text-center mt-4 mb-4">
          <h1 class="text-primary mb-2">Pricing built for your business</h1>
          <p class="lead">
            Start out small and upgrade as you grow - no surprises.
          </p>
          <div class="alert alert-info">
            Please note that a valid credit card is required to start the trial period. Your card will not be charged.<br>
            We ask for a credit card to validate your interest in our product and prevent abuse of the free trial.
          </div>
        </div>

        <div class="pricing-columns">
          <div class="row justify-content-center">

            <!-- BASIC-->
            <div class="col-xl-4 col-lg-6 mb-4 mb-xl-0">
              <div class="card h-100">
                <div class="card-header bg-transparent">
                  <span class="badge bg-primary-soft text-primary rounded-pill py-2 px-3 mb-2">BASIC</span>
                  <div class="pricing-columns-price">
                    $99
                    <span>/month</span>
                  </div>
                </div>
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Up to 10k scans/month
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Unlimited devices
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Unlimited applications
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Free updates
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      All supported 1D &amp; 2D symbologies
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Web-based analytics
                    </li>
                  </ul>
                </div>
                <a class="card-footer d-flex align-items-center justify-content-between" [routerLink]="" (click)="startBasicTrial()">
                  Start free 30-day trial
                  <i-feather name="arrow-right"></i-feather>
                </a>
              </div>
            </div>

            <!-- PRO -->
            <div class="col-xl-4 col-lg-6 mb-4 mb-xl-0">
              <div class="card h-100">
                <div class="card-header bg-transparent">
                  <span class="badge bg-secondary text-light rounded-pill py-2 px-3 mb-2">PROFESSIONAL</span>
                  <div class="pricing-columns-price">
                    $249
                    <span>/month</span>
                  </div>
                </div>
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Everything in BASIC
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Up to 100k scans/month
                    </li>
                    <li class="list-group-item">
                      <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                      Priority support
                    </li>
                  </ul>
                </div>
                <a class="card-footer d-flex align-items-center justify-content-between text-secondary" [routerLink]="" (click)="startProTrial()">
                  Start free 30-day trial
                  <i-feather name="arrow-right"></i-feather>
                </a>
              </div>
            </div>

            <!-- Enterprise -->
            <div class="col-xl-4 col-lg-6">
              <div class="pricing-table">
                <div class="card h-100">
                  <div class="card-header bg-transparent">
                    <span class="badge bg-dark text-light rounded-pill py-2 px-3 mb-2">ENTERPRISE</span>
                    <div class="pricing-columns-price">
                      <span>Request a quote</span>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <ul class="list-group list-group-flush">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Everything in PROFESSIONAL
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Unlimited scans
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Unlimited devices
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Custom branding of scanner UI
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Fully offline-capable
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Integration consulting services
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Direct line to engineering
                        </li>
                        <li class="list-group-item">
                          <i-feather name="check-circle" class="text-primary me-2"></i-feather>
                          Pay by invoice, annual billing
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <a class="card-footer d-flex align-items-center justify-content-between text-secondary" href="mailto:sales@pixelverse.ch">
                    Request a quote
                    <i-feather name="arrow-right"></i-feather>
                  </a>
                </div>
              </div>
            </div> <!-- enterprise -->

          </div> <!-- pricing-columns -->
        </div> <!--row -->

        <div class="row mt-4">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [disabled]="hasAcceptedLicenseAgreement" [(ngModel)]="hasAcceptedLicenseAgreement" id="licenseAgreementAcceptedCheckbox">
              <label class="form-check-label" for="licenseAgreementAcceptedCheckbox">
                I accept the terms of the
                <a href="https://strich.io/assets/legal/STRICH_Subscription_License_Agreement.pdf" target="_blank">Subscription License Agreement</a>.
              </label>
            </div>
          </div>

        </div>
      </div> <!-- container -->

    </main>
    <app-footer></app-footer>
  </div>
</div>

<div id="licenseAgreementModal" class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Subscription License Agreement</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Before starting a subscription, you need to accept the terms of the
        <a href="https://strich.io/assets/legal/STRICH_Subscription_License_Agreement.pdf" target="_blank">Subscription License Agreement</a>.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="acceptLicenseAgreement()">I Accept</button>
      </div>
    </div>
  </div>
</div>

<div id="preparingAccountModal" class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Subscription License Agreement</h5>
      </div>
      <div class="modal-body">
        <p>
          Please wait for your subscription to be applied to your account.
        </p>
        <progress>

        </progress>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="acceptLicenseAgreement()">I Accept</button>
      </div>
    </div>
  </div>
</div>

